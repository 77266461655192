import {
  BookOpen,
  Briefcase,
  Calendar,
  //CheckSquare,
  CreditCard,
  //Grid,
  Heart,
  Layout,
  List,
  //Map,
  ShoppingCart,
  //PieChart,
  Sliders,
  Users,
} from "react-feather";
import aicon from "./ai_icon.png";
const AIcon = ({ size = 22 }) => (
  <img src={aicon} alt="A Icon" style={{ width: size, height: size }} />
);
const pagesSection = [
  {
    href: "/riiseassistant",
    icon: AIcon,
    title: "RIISE Assistant",
  },
  {
    href: "/dashboard/default",
    icon: Sliders,
    title: "Overview Reporting",
  },
  {
    href: "/myassets",
    icon: Briefcase,
    title: "Asset Manager",
  },
  {
    href: "/compare",
    icon: Calendar,
    title: "Compare Assets",
  },
  {
    href: "/newinvestments",
    icon: CreditCard,
    title: "Investments Search",
  },
  {
    href: "/risk",
    icon: List,
    title: "Risk Assessment",
  },
  {
    href: "/retrofitmodeling",
    icon: Briefcase,
    title: "Retrofit Modeling",
  },
  {
    href: "/network",
    icon: Heart,
    title: "Supplier Network (Coming Soon!)",
  },
];

/*
const elementsSection = [
  {
    href: "/components",
    icon: Grid,
    title: "Components",
    children: [
      {
        href: "/components/alerts",
        title: "Alerts",
      },
      {
        href: "/components/accordion",
        title: "Accordion",
      },
      {
        href: "/components/avatars",
        title: "Avatars",
      },
      {
        href: "/components/badges",
        title: "Badges",
      },
      {
        href: "/components/buttons",
        title: "Buttons",
      },
      {
        href: "/components/cards",
        title: "Cards",
      },
      {
        href: "/components/chips",
        title: "Chips",
      },
      {
        href: "/components/dialogs",
        title: "Dialogs",
      },
      {
        href: "/components/lists",
        title: "Lists",
      },
      {
        href: "/components/menus",
        title: "Menus",
      },
      {
        href: "/components/pagination",
        title: "Pagination",
      },
      {
        href: "/components/progress",
        title: "Progress",
      },
      {
        href: "/components/snackbars",
        title: "Snackbars",
      },
      {
        href: "/components/tooltips",
        title: "Tooltips",
      },
    ],
  },
  {
    href: "/charts",
    icon: PieChart,
    title: "Charts",
    children: [
      {
        href: "/charts/chartjs",
        title: "Chart.js",
      },
      {
        href: "/charts/apexcharts",
        title: "ApexCharts",
      },
    ],
  },
  {
    href: "/forms",
    icon: CheckSquare,
    title: "Forms",
    children: [
      {
        href: "/forms/pickers",
        title: "Pickers",
      },
      {
        href: "/forms/selection-controls",
        title: "Selection Controls",
      },
      {
        href: "/forms/selects",
        title: "Selects",
      },
      {
        href: "/forms/text-fields",
        title: "Text Fields",
      },
      {
        href: "/forms/editors",
        title: "Editors",
      },
      {
        href: "/forms/formik",
        title: "Formik",
      },
    ],
  },
  {
    href: "/tables",
    icon: List,
    title: "Tables",
    children: [
      {
        href: "/tables/simple-table",
        title: "Simple Table",
      },
      {
        href: "/tables/advanced-table",
        title: "Advanced Table",
      },
      {
        href: "/tables/data-grid",
        title: "Data Grid",
      },
    ],
  },
  {
    href: "/icons",
    icon: Heart,
    title: "Icons",
    children: [
      {
        href: "/icons/material-icons",
        title: "Material Icons",
      },
      {
        href: "/icons/feather-icons",
        title: "Feather Icons",
      },
    ],
  },
  {
    href: "/maps",
    icon: Map,
    title: "Maps",
    children: [
      {
        href: "/maps/google-maps",
        title: "Google Maps",
      },
      {
        href: "/maps/vector-maps",
        title: "Vector Maps",
      },
    ],
  },
];
*/

/*
const docsSection = [
  {
    href: "/documentation/welcome",
    icon: BookOpen,
    title: "Documentation",
  },
  {
    href: "/changelog",
    icon: List,
    title: "Changelog",
    badge: "v4.4.0",
  },
];
*/

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  } /* ,
  {
    title: "Elements",
    pages: elementsSection,
  },
  {
    title: "Mira Pro",
    pages: docsSection,
  }, */,
];
export default navItems;
