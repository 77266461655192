import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import Chart from "react-apexcharts";

import { CardContent, Card as MuiCard, Typography } from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 350px;
  width: 100%;
`;

const AreaChart2 = ({ theme, title, description, ydata }) => {
  const data = [
    {
      name: "Portfolio",
      data: ydata,
    },
  ];

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      padding: {
        left: 50, // or whatever value that works
        right: 5,
        top: 5,
        bottom: 5,
      },
    },
    xaxis: {
      type: "datetime",
      categories: ["2023-09-19T00:30:00", "2050-09-19T00:30:00"],
    },
    yaxis: {
      min: 0,
      max: 5,
      title: {
        text: "BREEAM Rating", // Label for x-axis
        offsetX: 5,
        offsetY: 10,
        style: {
          color: "#333",
          fontSize: "14px",
          fontWeight: 600,
        },
      },
      labels: {
        formatter: function (val) {
          const categories = ["", "Pass", "Good", "Very Good", "Excellent"];
          return categories[val] || "";
        },
        offsetX: 40,
        offsetY: 0,
        style: {
          fontSize: "12px", // Reduce the font size
        },
      },
    },
    legend: {
      show: true,
      position: "top", // You can change the position to 'bottom', 'left', 'right', etc.
      horizontalAlign: "center", // Can be 'left', 'center', or 'right'
      offsetX: 0,
      offsetY: 10,
      labels: {
        colors: "#333", // You can set an array of colors for each series here
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        radius: 12,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    colors: [
      "#50c6fe",
      theme.palette.success.light,
      theme.palette.warning.light,
      theme.palette.error.light,
      theme.palette.info.light,
    ],
  };

  return (
    <Card mb={1}>
      <CardContent style={{ backgroundColor: "#e1e0e7" }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {description}
        </Typography>

        <Spacer mb={6} />

        <ChartWrapper>
          <Chart options={options} series={data} type="area" height="350" />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(AreaChart2);
