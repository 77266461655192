import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  Snackbar,
} from "@mui/material";
import { spacing } from "@mui/system";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

function SignUp() {
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false); // Added state for Snackbar
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [isVerificationStep, setIsVerificationStep] = React.useState(false);
  const onSignUp = async (email, password) => {
    try {
      await axios.post("https://18.219.186.224:8000/auth/signup/", {
        email,
        password,
      });
      setIsVerificationStep(true);
    } catch (error) {
      throw error;
    }
  };

  const onVerify = async (email, code) => {
    try {
      // Call your backend endpoint for verification
      await axios.post("http://18.219.186.224:8000/auth/verify/", {
        email,
        code,
      });
      navigate("/"); // Navigate to sign-in page or dashboard
    } catch (error) {
      const message = error.response?.data?.message || "Verification failed";
      setSnackbarMessage(message);
      setSnackbarOpen(true);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    password: isVerificationStep
      ? Yup.string().max(255)
      : Yup.string().max(255).required("Password is required"),
    confirmPassword: isVerificationStep
      ? Yup.string()
      : Yup.string()
          .oneOf(
            [Yup.ref("password"), null],
            "Both passwords need to be the same"
          )
          .required("Confirm Password is required"),
    verificationCode: isVerificationStep
      ? Yup.string().required("Verification code is required")
      : Yup.mixed().notRequired(),
  });

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      <Formik
        initialValues={{
          email: "",
          password: "",
          confirmPassword: "",
          verificationCode: "",
          submit: false,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if (!isVerificationStep) {
              await onSignUp(values.email, values.password);
            } else {
              await onVerify(values.email, values.verificationCode);
            }
          } catch (error) {
            const message =
              error.response?.data?.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSnackbarMessage(message);
            setSnackbarOpen(true);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              type="email"
              name="email"
              label="Email address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            {!isVerificationStep && (
              <>
                <TextField
                  type="password"
                  name="password"
                  label="Password"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
                <TextField
                  type="password"
                  name="confirmPassword"
                  label="Confirm Password"
                  value={values.confirmPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
              </>
            )}
            {isVerificationStep && (
              <TextField
                type="text"
                name="verificationCode"
                label="Verification Code"
                value={values.verificationCode}
                error={Boolean(
                  touched.verificationCode && errors.verificationCode
                )}
                fullWidth
                helperText={touched.verificationCode && errors.verificationCode}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              {isVerificationStep ? "Verify" : "Sign up"}
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
}

export default SignUp;
