import React, { useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import Chart from "react-apexcharts";

import {
  Button,
  CardContent,
  Card as MuiCard,
  Typography,
  Grid,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 350px;
  width: 100%;
`;

const AreaChart3 = ({ theme, title, description, fullYData }) => {
  const [showUntil2050, setShowUntil2050] = useState(false);
  const ydata = showUntil2050 ? fullYData || [] : (fullYData || []).slice(0, 8);
  const toggleShow = () => {
    setShowUntil2050(!showUntil2050);
  };

  const data = [
    {
      name: "Portfolio",
      data: ydata,
    },
  ];

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
      title: {
        text: "$USD", // Label for x-axis
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#333",
          fontSize: "14px",
          fontWeight: 600,
        },
      },
    },
    xaxis: {
      type: "datetime",
      categories: showUntil2050
        ? [
            "2023-09-19T00:30:00",
            "2024-09-19T00:30:00",
            "2025-09-19T00:30:00",
            "2026-09-19T00:30:00",
            "2027-09-19T00:30:00",
            "2028-09-19T00:30:00",
            "2029-09-19T00:30:00",
            "2030-09-19T00:30:00",
            "2031-09-19T00:30:00",
            "2032-09-19T00:30:00",
            "2033-09-19T00:30:00",
            "2034-09-19T00:30:00",
            "2035-09-19T00:30:00",
            "2036-09-19T00:30:00",
            "2037-09-19T00:30:00",
            "2038-09-19T00:30:00",
            "2039-09-19T00:30:00",
            "2040-09-19T00:30:00",
            "2041-09-19T00:30:00",
            "2042-09-19T00:30:00",
            "2043-09-19T00:30:00",
            "2044-09-19T00:30:00",
            "2045-09-19T00:30:00",
            "2046-09-19T00:30:00",
            "2047-09-19T00:30:00",
            "2048-09-19T00:30:00",
            "2049-09-19T00:30:00",
            "2050-09-19T00:30:00",
          ]
        : [
            "2023-09-19T00:30:00",
            "2024-09-19T00:30:00",
            "2025-09-19T00:30:00",
            "2026-09-19T00:30:00",
            "2027-09-19T00:30:00",
            "2028-09-19T00:30:00",
            "2029-09-19T00:30:00",
            "2030-09-19T00:30:00",
          ],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    colors: [
      "#86d184",
      "#50c6fe",
      theme.palette.warning.light,
      theme.palette.error.light,
      theme.palette.info.light,
    ],
  };

  return (
    <Card style={{ backgroundColor: "#e1e0e7" }} mb={1}>
      <CardContent>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {description}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: "#50c6fe", color: "white" }}
              onClick={toggleShow} // Toggle state
            >
              Toggle to {showUntil2050 ? "2030" : "2050"}{" "}
              {/* Display appropriate label */}
            </Button>
          </Grid>
        </Grid>
        <Spacer mb={6} />

        <ChartWrapper>
          <Chart options={options} series={data} type="area" height="350" />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(AreaChart3);
