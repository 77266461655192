import React from "react";
import styled from "@emotion/styled";
import { MoreVertical } from "react-feather";

import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: #50c6fe;
  color: ${(props) => props.theme.palette.common.white};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  background-color: #e1e0e7;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

// Data
let id = 0;
function createData(address, pev, co2, bream, epc, risk, leed) {
  id += 1;
  return { id, address, pev, co2, bream, epc, risk, leed };
}

const DashboardTable3 = ({ title, data }) => {
  const rows = data
    ? data.map((item, index) =>
        createData(
          item.address,
          item.optimized_PEV,
          item.optimized_Co2,
          <Chip label={item.optimized_BREEAM_rating} />,
          item.optimized_EPC_rating,
          item.optimized_stranded_risk,
          item.leed_cert
        )
      )
    : [];
  return (
    <Card mb={6}>
      <CardHeader style={{ backgroundColor: "#e1e0e7" }} title={title} />
      <Paper>
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Address</TableCell>
                <TableCell>Annual PEV</TableCell>
                <TableCell>Annual CO2 Emissions</TableCell>
                <TableCell>Stranded Risk</TableCell>
                <TableCell>LEED Rating</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.address}
                  </TableCell>
                  <TableCell>{`${row.pev} Kw/M2`}</TableCell>
                  <TableCell>{`${row.co2} Kg/M2`}</TableCell>
                  <TableCell>{row.risk}</TableCell>
                  <TableCell>{row.leed}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </Paper>
    </Card>
  );
};

export default DashboardTable3;
