import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const onSignIn = async (email, password) => {
    try {
      const response = await axios.post(
        "https://riiseapp.com/api/auth/signin/",
        {
          email: email,
          password: password,
        }
      );

      if (!response.data.id_token || !response.data.access_token) {
        throw new Error("Invalid username or password.");
      }

      // Handle response, store tokens, navigate, etc.
      console.log(response.data);
      localStorage.setItem("id_token", response.data.id_token); // Replace 'token' with the actual property name
      localStorage.setItem("access_token", response.data.access_token);
      navigate("/dashboard/default");
    } catch (error) {
      const message = error.response?.data?.message || "Something went wrong";
      setSnackbarMessage(message);
      setSnackbarOpen(true);
      throw error;
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await onSignIn(values.email, values.password);
        } catch (error) {
          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
          setSnackbarMessage(message);
          setSnackbarOpen(true);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{ backgroundColor: "#50c6fe", color: "white" }}
            disabled={isSubmitting}
          >
            Sign in
          </Button>
          <Button component={Link} to="/auth/sign-up" fullWidth color="primary">
            Sign up
          </Button>
          <Button
            component={Link}
            to="/auth/reset-password"
            fullWidth
            color="primary"
          >
            Forgot password
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
