import React from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";

import { CssBaseline } from "@mui/material";
import { useLocation } from "react-router-dom";
import Settings from "../components/Settings";
import GlobalStyle from "../components/GlobalStyle";

const Root = styled.div`
  background-image: ${(props) =>
    props.bgImage ? `url(${props.bgImage})` : "/Login_Image.png"};
  background-size: cover; // Cover the entire container
  background-repeat: no-repeat; // Do not repeat the image
  background-position: center; // Center the image
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const Auth = ({ children }) => {
  const location = useLocation();
  let bgImage;

  if (location.pathname === "/") {
    bgImage = "/Login_Image.png";
  } else if (location.pathname === "/auth/landing") {
    bgImage = "/Background_Image.png";
  }
  return (
    <Root bgImage={bgImage}>
      <CssBaseline />
      <GlobalStyle />
      {children}
      <Outlet />
    </Root>
  );
};

export default Auth;
