import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import { green } from "@mui/material/colors";

import { Box, Chip, Drawer as MuiDrawer, ListItemButton } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: #e9edfa;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: #e1e0e7;
  }
`;

const Brand2 = styled.img`
  width: 100px;
  height: 38px;
  margin-top: 32px;
`;

const Sidebar = ({ items, showFooter = true, ...rest }) => {
  const [isClickable, setIsClickable] = useState(true);

  // Function to disable and enable the button
  const handleNavigation = (event) => {
    if (!isClickable) {
      event.preventDefault(); // Prevent navigation if not clickable
      console.log("Navigation blocked due to rapid clicking.");
    } else {
      setIsClickable(false);
      setTimeout(() => {
        setIsClickable(true);
      }, 2000); // Cooldown of 1 second
    }
  };
  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/" onClick={handleNavigation}>
        <Box ml={1}>
          <Brand2 src="/Logo_Blue.png" alt="Logo" />
        </Box>
      </Brand>
      <SidebarNav
        items={items}
        isClickable={isClickable}
        handleNavigation={handleNavigation}
      />
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
