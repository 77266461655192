import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { Doughnut } from "react-chartjs-2";
import { MoreVertical } from "react-feather";

import {
  orange,
  green,
  red,
  yellow,
  brown,
  purple,
  lime,
  pink,
  blueGrey,
} from "@mui/material/colors";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 168px;
  background-color: #e1e0e7;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;
  background-color: #e1e0e7;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
  background-color: #e1e0e7;
`;

const GreenText = styled.span`
  color: ${() => green[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

const RedText = styled.span`
  color: ${() => red[400]};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`;

let id = 0;
function createData(product, val, percent) {
  id += 1;
  return { id, product, val, percent };
}

const getChartData = (upgradeInfo, innerLabel) => {
  let chartData = [];
  let rows = [];
  let total = 0;
  let labels = [];

  // Calculate the total sum first
  for (const [key, item] of Object.entries(upgradeInfo)) {
    labels.push(key);
    if (innerLabel === "OPEX Savings") {
      total += item.annual_savings;
    } else if (innerLabel === "CO2 Savings") {
      total += item.annual_co2_savings;
    }
  }

  // Loop again to get the percentage and populate chartData and rows
  for (const [key, item] of Object.entries(upgradeInfo)) {
    let value = 0;
    if (innerLabel === "OPEX Savings") {
      value = item.annual_savings;
    } else if (innerLabel === "CO2 Savings") {
      value = item.annual_co2_savings;
    }

    const percent = (value / total) * 100; // calculate percentage
    chartData.push(value);

    const row = createData(key, value, percent.toFixed(0));
    rows.push(row);
  }

  return { chartData, rows, labels };
};
const DoughnutChart2 = ({
  theme,
  title,
  upgradeInfo,
  innerLable,
  tabelLables,
  percent,
}) => {
  const { chartData, rows, labels } = getChartData(upgradeInfo, innerLable);
  //const chartData = [100];
  //const rows = [];
  const hue = innerLable === "OPEX Savings" ? 206 : 160;
  const data = {
    labels: labels, // ["Social", "Search Engines", "Direct", "Other"]
    datasets: [
      {
        data: chartData, //[260, 125, 54, 146]
        backgroundColor: Array.from(
          { length: chartData.length },
          (_, i) => `hsl(165, 59%, ${90 - i * (50 / chartData.length)}%)`
        ),
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "50%",
  };

  return (
    <Card mb={6}>
      <CardHeader style={{ backgroundColor: "#e1e0e7" }} title={title} />
      <CardContent style={{ backgroundColor: "#e1e0e7" }}>
        <ChartWrapper>
          <DoughnutInner>
            <Typography variant="h4">{`-${percent}%`}</Typography>
            <Typography variant="caption">{innerLable}</Typography>
          </DoughnutInner>
          <Doughnut data={data} options={options} />
        </ChartWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{tabelLables[0]}</TableCell>
              <TableCell align="right">{tabelLables[1]}</TableCell>
              <TableCell align="right">{tabelLables[2]}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.product}
                </TableCell>
                <TableCell align="right">{`${row.val.toLocaleString()} Kg`}</TableCell>
                <TableCell align="right">{`${row.percent}%`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default withTheme(DoughnutChart2);
