import React, { useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { Line } from "react-chartjs-2";
import { MoreVertical } from "react-feather";

import {
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import { alpha } from "@mui/material/styles";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 378px;
`;

function LineChart2({ theme, title, fy1, fy2, fy3, fy4 }) {
  const [showUntil2050, setShowUntil2050] = useState(false);
  const y1 = showUntil2050 ? fy1 || [] : (fy1 || []).slice(0, 8);
  const y2 = showUntil2050 ? fy2 || [] : (fy2 || []).slice(0, 8);
  const y3 = showUntil2050 ? fy3 || [] : (fy3 || []).slice(0, 8);
  const y4 = showUntil2050 ? fy4 || [] : (fy4 || []).slice(0, 8);
  const toggleShow = () => {
    setShowUntil2050(!showUntil2050);
  };
  const data = {
    labels: showUntil2050
      ? [
          "2023",
          "2024",
          "2025",
          "2026",
          "2027",
          "2028",
          "2029",
          "2030",
          "2031",
          "2032",
          "2033",
          "2034",
          "2035",
          "2036",
          "2037",
          "2038",
          "2039",
          "2040",
          "2041",
          "2042",
          "2043",
          "2044",
          "2045",
          "2046",
          "2047",
          "2048",
          "2049",
          "2050",
        ]
      : ["2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"],
    datasets: [
      {
        label: "CAPEX",
        fill: true,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, alpha(theme.palette.secondary.main, 0.0875));
          gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

          return gradient;
        },
        borderColor: "#f7bfc2",
        borderWidth: 3,
        tension: 0.4,
        data: y1,
      },
      {
        label: "OPEX",
        fill: true,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, alpha(theme.palette.secondary.main, 0.0875));
          gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

          return gradient;
        },
        borderColor: "#50c6fe",
        borderWidth: 3,
        borderDash: [40, 40],
        tension: 0.4,
        data: y2,
      },
      {
        label: "CO2",
        fill: true,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, alpha(theme.palette.secondary.main, 0.0875));
          gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

          return gradient;
        },
        borderColor: "#86d184",
        borderWidth: 3,
        tension: 0.4,
        data: y3,
      },
      {
        label: "ROI",
        fill: true,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, alpha(theme.palette.secondary.main, 0.0875));
          gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

          return gradient;
        },
        borderColor: "#c98af1",
        borderWidth: 3,
        tension: 0.4,
        data: y4,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle", // To specify that it should be circle
          boxWidth: 12, // Adjust this size as per your need
          padding: 20, // Adjust the spacing
        },
      },
    },
    scales: {
      x: {
        grid: {
          color: "rgba(0,0,0,0.0)",
        },
      },
      y: {
        title: {
          display: true,
          text: "Normalized Data", // <-- Adding y-axis label here
          color: "#333", // Optional: text color
          font: {
            size: 14, // Optional: text size
            weight: "bold", // Optional: text weight
          },
          padding: { top: 10, bottom: 10 }, // Optional: padding
        },
        grid: {
          color: "rgba(0,0,0,0.0375)",
          fontColor: "#fff",
        },
      },
    },
  };

  return (
    <Card style={{ backgroundColor: "#e1e0e7" }} mb={6}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <CardHeader title={title} />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            style={{ backgroundColor: "#50c6fe", color: "white" }}
            onClick={toggleShow} // Toggle state
          >
            Toggle to {showUntil2050 ? "2030" : "2050"}{" "}
            {/* Display appropriate label */}
          </Button>
        </Grid>
      </Grid>
      <CardContent>
        <ChartWrapper>
          <Line data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
}
export default withTheme(LineChart2);
