import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Avatar, Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";

const Brand = styled.img`
  width: 200px;
  height: 75px;
  margin: 0 auto 20px; // Zeroing out left and right margins and auto-setting them to align the item in the center.
  display: block; // Making sure it takes the full width to center properly.
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
  max-width: 560px;
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;

const PageContainer = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  padding: ${(props) => props.theme.spacing(3)};
`;

function SignIn() {
  return (
    <PageContainer>
      <Wrapper>
        <Helmet title="Sign In" />
        <Brand src="/Email_Sig_Logo.jpg" alt="Logo" />
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Welcome back!
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Sign in to your account to continue
        </Typography>

        <SignInComponent />
      </Wrapper>
    </PageContainer>
  );
}

export default SignIn;
