import React, { useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Button,
  Grid,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import { useNavigate } from "react-router-dom";
import { darken } from "polished";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Brand = styled.img`
  width: 200px;
  height: 75px;
  margin: 0 auto 20px; // Zeroing out left and right margins and auto-setting them to align the item in the center.
  display: block; // Making sure it takes the full width to center properly.
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
  max-width: 560px;
`;
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 660px;
  }
`;
const Search = styled.div`
  border-radius: 8px; // Increase corner rounding
  border: 2px solid #50c6fe; // Add a blue border
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 50%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;

const PageContainer = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  padding: ${(props) => props.theme.spacing(3)};
`;
const defaultStyle = {
  backgroundColor: "#50c6fe",
  color: "white",
  marginLeft: "10px",
};

const hoverStyle = {
  backgroundColor: "white",
  color: "#50c6fe",
  marginLeft: "10px",
};
const activeButtonStyle = {
  backgroundColor: "#50c6fe",
  color: "white",
};

const inactiveButtonStyle = {
  backgroundColor: "white",
  color: "#50c6fe",
};

function Landing() {
  const navigate = useNavigate();
  const [input, setInput] = useState("");
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);
  const [activeButton, setActiveButton] = useState(1);
  const [responseText, setResponseText] = useState(""); // State to store the response text

  const handleChange = (event) => {
    setInput(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const id_token = localStorage.getItem("id_token");
    try {
      const response = await axios.post(
        "http://18.219.186.224:8000/api/openai/",
        { input, id_token }
      );
      setResponseText(response.data.openai_response); // Update the responseText state with the API response
      setInput("");
      console.log(response);
    } catch (error) {
      console.error("Error submitting the form", error);
      setResponseText("An error occurred while fetching data."); // Set an error message
    }
  };
  const handleDashboardClick = () => {
    navigate("/dashboard/default");
  };
  return (
    <PageContainer>
      <Wrapper>
        <Helmet title="Landing" />
        <Brand src="/Email_Sig_Logo.jpg" alt="Logo" />
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <form
              onSubmit={handleSubmit}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Search>
                <Input
                  placeholder={t(
                    "What do you want to know about your portfolio"
                  )}
                  value={input}
                  onChange={handleChange}
                />
              </Search>
              <Button
                type="submit"
                variant="contained"
                style={hover ? hoverStyle : defaultStyle}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
              >
                Ask!
              </Button>
            </form>
          </div>
          <Typography
            variant="body1"
            style={{
              marginTop: 20,
              minHeight: "50px",
              backgroundColor: responseText ? "transparent" : "#f0f0f0",
              padding: "10px",
              border: responseText ? "none" : "1px dashed #ccc",
              color: responseText ? "inherit" : "#999",
            }}
          >
            {responseText || "Your results will appear here..."}
          </Typography>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              style={activeButtonStyle}
              onClick={handleDashboardClick}
            >
              Go to Dashboard
            </Button>
          </div>
        </Grid>
      </Wrapper>
    </PageContainer>
  );
}

export default Landing;
